.parents-component-2021{
    padding-top: 62px + 16px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    min-height: 100vh;
    width: calc(100vw - 230px);
    box-sizing: border-box;
}

.data-container-wrapper{
    width: calc(100% - 25px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.input-data-header{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #222222;
}

.bread-cab-container{
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    align-items: center;
}

.bread-grey{
    margin-right: 5px;
    color: #999999;
}

.bread-blue{
    color: #3c98dc;
    margin-right: 5px;
    cursor: pointer;
}

.bread-blue:hover{
    text-decoration: underline;
}

.menu-container{
    display: flex;
    flex-direction: row;
}

.logo-binabudaya-sidebar{
    padding: 0px 22px;
    margin-bottom: 50px;
    margin-top: 48px;
}

.logo-binabudaya-circle-sidebar{
    width: 80%;
    margin: 0 auto;
}

.menu-list-font-active{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-left: 15px;
    text-align: left;
}

.menu-list-font-active-accordion{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 'normal';
    font-size: 16px;
    line-height: 19px;
    margin-left: 15px;
}

.menu-list-font{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-left: 15px;
}

.wrapper-btn-list-menu{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.appbar-font-title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    margin-left: 18px;
}

.appbar-title-wrapper{
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    width: 100%;
}

.popup-welcome{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #85CDFF;
}

.custom-popup{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: auto;
}

.text-menu-item-popup{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 14px;
    color: #999999;
}

.MuiList-padding{
    padding: 0px !important;
}

.drawer-footer-small{
    background: #FAFAFA;
    min-height: 55px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 14px;
    text-align: center;
    color: #BBBBBB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    padding-bottom: 20px;
}

.drawer-footer{
    background: #FAFAFA;
    margin-top: auto;
    min-height: 55px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #BBBBBB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.btn-back{
    display: flex;
    width: 102px;
    height: 36px;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #3598DC;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.icn-back{
    color: #3598DC;
    margin-right: 6px;
    font-size: 19px !important;
}

.con-beri-nilai{
    margin-top: 43px;
    max-width: 1016px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 63px !important;
}

.paper-beri-nilai{
    margin-top: 18px;
    .PrivateNotchedOutline-root-1.PrivateNotchedOutline-root-1{
        border-width: 0.5px;
    }
    .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline{
        border-color: #D1D1D1;
    }
}

.tf-nilai-cus{
    .MuiOutlinedInput-root.MuiOutlinedInput-root{
        height: 44px;
        background: #FAFAFA;
    }
}

.search-nilai-cus{
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
        padding: 0px 15px;
    }
    .MuiOutlinedInput-root.MuiOutlinedInput-root{
        height: 44px;
        background: #FFFFFF;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0px;
    }
    .MuiInputAdornment-root.MuiInputAdornment-root{
        max-height: none;
        background:#FAFAFA;
        border-left: 0.5px solid #D1D1D1;
        height: 100%;
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]{
        padding: 0 !important;
    }
}

.sch-auto{
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #999999;
}

.tf-nilai-cus-multi{
    .MuiOutlinedInput-root.MuiOutlinedInput-root{
        background: #FAFAFA;
    }
}

.placeholder{
    text-align: left;
    color: rgba(0, 0, 0, 0.35);
    font-weight: 400 !important;
  }

.input-textfield-cus.input-textfield-cus{
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
}

.head-table-seleksi.head-table-seleksi{
    background: #FAFAFA;
    .MuiTableCell-head.MuiTableCell-head{
        color: #888888;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
    }
  
}

.btn-ad-obs.btn-ad-obs{
    width: 170px;
    height: 44px;
    background: rgba(53, 152, 220, 0.1);
    border: 1px solid #3598DC;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: none;
    &:hover,
    &:active,
    &:focus{
        background: rgba(53, 152, 220, 0.1);
    }
}

.btn-hapus-obs.btn-hapus-obs{
    width: 170px;
    height: 44px;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: none;
    box-shadow: none;
    color: #666666;
    &:hover,
    &:active,
    &:focus{
        box-shadow: none;
    }
}

.PIN-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.pin-font-title-top{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #222222;
}

.badscrub-direction-wrap{
    display: flex;
    flex-direction: row;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-top: 16px;
    cursor: default;
}

.badscrub-blue{
    color: #3598DC;
}

.badscrub-grey{
    color: #C1C1C1;
}

.spacing-slash{
    margin: 0 5px;
}

.div-det-nilai{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 0px;
    border-bottom: 0.5px solid #DDDDDD;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #646464;
    .ta-right{
        color: #18191F !important;
    }
}

.div-obs-det{
    height: 38px;
    background: rgba(53, 152, 220, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 18px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3598DC;
    width: fit-content;
}

.btn-wawancara-blue{
    background: #3598DC;
    border-radius: 100px;
    width: 230px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    cursor: pointer;
}

.data-pribadi-input-area{
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    border-radius: 4px;
    width: 100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    background: #FAFAFA;
    min-height: 100px;
    overflow: hidden
}

.data-pribadi-input-area:focus{
    outline: none;
}

.btn-wawancara-grey{
    cursor: pointer;
    background: #E6E7E9;
    border-radius: 100px;
    width: 230px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
}

.drawer-access{
    border-bottom: 1px solid #DDDDDD;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #9E9E9E;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 13px;
    cursor: pointer;
}

.drawer-access-menu{
    border-bottom: 1px solid #DDDDDD;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #696969;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px 13px;
}

.drawer-wrapper{
    margin-top: auto;
}

.act-sort{
    cursor: pointer;
    display: block;
    color: #3598DC    ;
}

.non-sort{
    cursor: pointer;
}
