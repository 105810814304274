.filter-table-peserta-placeholder{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #999999;
    margin-top: -5px;
}

.daftar-table{
    .MuiTableCell-root{
    font-size: 14px !important;
    line-height: 16px !important;
    vertical-align: middle !important;
    text-align: center !important;
    }
}

.value-selectable-title{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 16px;
    color: #444444 !important;
    height: 44px;
    display: flex;
    justify-content: center !important;
    border-top: 0.5px solid #EBEBEB !important;
    background-color: #F9F9F9 !important;
    pointer-events: 'none';
}

.value-selectable{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center !important;
    color: #666666 !important;
    height: 34px;
    display: flex;
    border-top: 0.5px solid #EBEBEB !important;
    background-color: white !important;
}

.value-selectable:hover{
    background: #EBF5FB !important;
    color: #3598DC !important;
}

.value-selectable-left{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: left !important;
    color: #666666 !important;
    height: 34px;
    display: flex;
    border-top: 0.5px solid #EBEBEB !important;
    background-color: white !important;
}

.value-selectable-left:hover{
    background: #EBF5FB !important;
    color: #3598DC !important;
}

.value-selectable-active{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center !important;
    background: #EBF5FB !important;
    color: #3598DC !important;
    height: 34px;
    display: flex;
}

.value-selectable-active-left{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: left !important;
    background: #EBF5FB !important;
    color: #3598DC !important;
    height: 34px;
    display: flex;
}

.value-selectable-active.MuiListItem-root{
    justify-content: center !important;
}

.value-selectable.MuiListItem-root{
    justify-content: center !important;
}

.value-selectable-disabled{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center !important;
    color: #D0D0D0;
    height: 34px;
    border-bottom: 0.5px solid #EBEBEB !important;
}

.value-selectable-disabled.MuiListItem-root{
    justify-content: center !important;
}

.btn-detail-peserta{
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 4px;
    width: 170px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
}
.btn-detail-peserta:hover{
    background: #3598DC;
    border-radius: 4px;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
}
.btn-detail-peserta:hover span {
    display:none;
}
.btn-detail-peserta:hover:before{
    content:"Edit Status";
}

.btn-detail-peserta-disabled{
    box-sizing: border-box;
    border-radius: 4px;
    width: 134px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    transition: 0.5s;
}

.popover-style-change-status{
    font-family: Roboto;
    width: 134px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #666666;
    background: white;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.04);
}

.popover-style-change-disabled{
    color: #BBBBBB;
    height: 34px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #EBEBEB;
}

.popover-style-change-status-btn{
    height: 34px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #EBEBEB;
}

.popover-style-change-status-btn:hover{
    background: #EBF5FB;
    color: #3598DC;
}

.btn-outlined.btn-outlined{
    text-transform: none;
    height: 34px;
    box-shadow: none;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    &:hover,
    &:focus,
    &:active{
        box-shadow: none;
        font-weight: normal;
        background-color: white;
        font-size: 14px;
        line-height: 16px;
    }
}

.btn-out-blue.btn-out-blue{
    background-color: rgba(53, 152, 220, 0.1);
    &:hover,
    &:focus,
    &:active{
        background-color: rgba(53, 152, 220, 0.1);
    }
}

.btn-contained.btn-contained{
    text-transform: none;
    height: 34px;
    box-shadow: none;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    &:hover,
    &:focus,
    &:active{
        box-shadow: none;
        background-color: #3598DC;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
}

.btn-ekspor.btn-ekspor{
    text-transform: none;
    width: 150px;
    height: 44px;
    box-shadow: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    background: #00B368;
    padding: 12px;
    &:hover,
    &:focus,
    &:active{
        box-shadow: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        background: #00B368;
    }
}

.arr-cus{
    color: #B4B4B4;
    font-size: 17px;
}

.el-cus{
    color: #B4B4B4;
    font-size: 12px;
}

.txt-scroll{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #888888;
    margin-bottom: 10px;
    text-align: left;
    margin-top: 10px;
}

.btn-pilihan.btn-pilihan{
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    background: #FFFFFF;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    min-width: 0px;
}

.menu-table{
    margin-top: 5px !important;
}

.menu-item-table.menu-item-table{
    font-size: 14px;
    line-height: 16px;
    color: #666666;
}

.btn-pilihan-aksi.btn-pilihan-aksi{
    width: 32px;
    height: 32px;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    background: #FFFFFF;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 6px;
    min-width: 0px;
}

.div-rank{
    display: flex;
    align-items: center;
    justify-content: center;
}




.peserta-selectable-aksi{
    width: 150px !important;
    text-align: center !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #3598DC !important;
    justify-content: center !important;
    background-color: white !important;
    border-bottom: 0.5px solid #EBEBEB !important;
    transition: 0.2s !important;
}

.peserta-selectable-aksi:hover{
    color: white !important;
    background-color: #3598DC !important;
}

.peserta-selectable-aksi-red{
    width: 150px !important;
    text-align: center !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #FF5579 !important;
    justify-content: center !important;
    background-color: white !important;
    border-bottom: 0.5px solid #EBEBEB !important;
}

.btn-buyback-yakin{
    width: 200px;
    height: 44px;
    background: #3598DC;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: 10px;
    transition: 0.5s;
}

.btn-buyback-tidak{
    width: 200px;
    height: 44px;
    // border: 1px solid #b92323;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.5s;
    background: #ee1616;
}

.btn-buyback-yakin:hover{
    background: #1f6da1;
}

.btn-buyback-tidak:hover{
    background: #961212;
}

.w-aksi.w-aksi{
    width: 111px;
    height: 32px;
    font-size: 13px;
    &:hover,
    &:focus,
    &:active{
        font-size: 13px;
    }
}



////table-custum

.col-fix{
    position: sticky;
    background: #ffffff;
    z-index: 20;
}

.table-fix.table-fix{
    border-top: 1px solid rgba(224, 224, 224, 1);
    border-radius: 2px;
    border-collapse: separate;
}

.select-page{
    border: 0.5px solid #D4D4D4;
    border-radius: 4px;
    font-size: 16px;
    color: #999999;
    width: 43px;
    height: 30px;
    outline: none;
    &:active,
    &:hover{
        outline: none;
    } 
}

.belum-lengkap-adm.belum-lengkap-adm{
    background: #FFEAB6;
    color: #FC6A00;
}

.belum-lengkap.belum-lengkap{
    background: #FFD9D9;
    color: #EC0101;
}

.txt-pagenation{
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #999999 !important;
}

.title-modal-input-nilai-wawancara{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #222222;
}

.swal2-html-container{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #666666;
}