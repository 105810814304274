.add-chapter-header{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #3598DC;
}

.data-pribadi-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.data-pribadi-font{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #666666;
    margin-right: 31px;
    min-width: 180px;
    text-align: right;
}

.data-pribadi-input{
    width: 100%;
    height: 44px;
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 0px 19px;
    color: #666666;
    transition: 0.5s;
}

.data-pribadi-input:focus{
    outline: none;
    border: 1px solid #3598DC;
}

.data-pribadi-input::placeholder{
    color: #C9C9C9;
}

.input-tambah-wrapper{
    margin-top: 45px;
    display: flex;
    flex-direction: column;
}

.email-small-info{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #999999;
    margin-right: auto;
}

.input-wrapper-data-pribadi{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.activation-input-62{
    width: 50px;
    height: 44px;
    border: none;
    outline: none;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    transition: 0.5s;
    text-align: right;
    background: #FAFAFA;
    border-radius: 4px;
}

.nohp-input-wrapper{
    width: 100%;
    background: #FAFAFA;
    display: flex;
    justify-content: flex-start;
}
.wrapper-tambah-chapter-btn-input{
    width: 100%;
    // margin-left: auto;
    // margin-right: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.wrapper-tambah-chapter-btn-input-right{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.nohp-input-wrapper{
    border: 0.5px solid #D1D1D1;
    border-radius: 4px;
}

.button-wrapper-save-input{
    margin-right: auto;
    margin-top: 30px;
    padding-bottom: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.btn-finalisasi-yakin{
    height: 44px;
    background: #3598DC;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    outline: none;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 10px;
    cursor: pointer;
}

.btn-finalisasi-tidak{
    height: 44px;
    background: #e50000;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    outline: none;
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

@media (max-width: 377px){
    .swal2-actions{
        display: flex;
        flex-direction: column;
    }
    .btn-finalisasi-yakin{
        margin-left: 0px;
        margin-top: 10px;
    }
}