.login-container-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100vh;
}

.login-left-wrapper{
    background: url('../../images/image.jpg');
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 100vh;
    display: block;
}

.homepage-wrapper{
    width: 90%;
    min-height: 100vh;
    scroll-behavior: smooth;
}
.appbar-homepage{
    width: 100%;
    top: 0px;
    position: fixed;
    -webkit-box-shadow: -10px 4px 105px 11px rgba(0,0,0,0.67);
    -moz-box-shadow: -10px 4px 105px 11px rgba(0,0,0,0.67);
    box-shadow: -10px 4px 105px 11px rgba(0,0,0,0.67);
    z-index: 99;
}

.image-logo-login{
    width: 382px;
}

.login-right-wrapper{
    padding: 55px;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.transparent-patern{
    background: linear-gradient(179.44deg, #3599dc9d 0.54%, rgba(53, 152, 220, 0) 99.47%);
    width: 100%;
    height: 100%;
    transform: matrix(1, 0, 0, -1, 0, 0);
}

.login-text-top-wrapper{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    color: #444444;
    margin-top: 47px;
    display: flex;
    flex-direction: column;
}

.login-text-middle-font{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    margin-top: 22px;
}

.login-font-title{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #666666;
    margin-top: 43px;
}

.password-wraper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.activation-input-theme{
    width: 100%;
    height: 44px;
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    transition: 0.5s;
    padding: 0px 19px;
    margin-top: 10px;
}

.activation-input-theme::placeholder {
    color: #D1D1D1;
}

.activation-input-theme:focus{
    outline: none;
    box-shadow: 0px 0px 5px 2px #B0DFFF;
    border: 1px solid #B0DFFF;
}

.eye-login{
    color: #D1D1D1;
    font-size: 26px;
    margin-left: -40px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.eye-login2{
    color: #D1D1D1;
    font-size: 26px;
    margin-left: -43px;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.content-card-wripper-login{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 35px;
    width: 100%;
}

.login-bottom-container-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;
}

.text-login-right-wripper{
    display: flex;
    flex-direction: column;
    transform: matrix(1, 0, 0, -1, 0, 0);
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 10px;
    padding-top: 20%;
    box-sizing: border-box;
}

.text-right-top{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 45px;
    line-height: 42px;
    color: #FFFFFF;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #3598DC;
    box-sizing: border-box;
}

.text-login-quotes{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    margin-top: 10px;
    line-height: 28px;
    box-sizing: border-box;
    color: #EAEAEA;
    -webkit-text-stroke-width: 0.3px;
    background-color: #3598DC;
    -webkit-text-stroke-color: #3598DC;
}

.lupa-password-font{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #3598DC;
    cursor: pointer;
}

.btn-989312312{
    width: 100%;
}

@media (max-width: 688px){
    .login-left-wrapper{
        display: none;
    }
    .login-right-wrapper{
        width: 100%;
    }
}