.logo-verification-binabudaya{
    width: 100%;
}

.box-verification-124{
    width: 400px;
    margin: auto auto;
    color: #fbffff;
    transition: 0.5s;
}

.loading-text-verification{
    margin-top: 5px;
}

@media (max-width: '425px'){
    .box-verification-124{
        width: 300px;
    }
    .loading-text-verification{
        font-size: 12px;
    }
}

@media (max-width: '325px'){
    .box-verification-124{
        width: 250px;
    }
}