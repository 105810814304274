.info-peserta-wrapper-container{
    background: #F4F7FC;
    padding-top: 82px;
    padding-bottom: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.info-peserta-wrapper{
    width: 80%;
}

.info-peserta-title-header{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    color: #222222;
    margin: 0 auto;
}

.card-info-peserta-picture{
    display: flex;
    flex-direction: row;
    background-color: white;
    border-radius: 4px;
    width: 100%;
    padding: 28px 30px;
    margin-top: 28px;
}

.info-peserta-picture{
    width: calc(472px / 3);
    height: calc(709px / 3);
    background-size: cover;
}

.card-info-pictures-wrap-one{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 30px;
}

.card-info-font-name{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    color: #444444;
    text-align: left;
}

.card-info-font-nourut{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #444444;
    text-align: left;
}

.card-info-button-row{
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    flex-wrap: wrap;
}

.card-info-pictures-wrap-two{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 2px solid #EBF5FB;
    margin-left: 61px;
    padding-left: 60px;
}

.card-info-litle-title-grey{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #999999;
    text-align: left;
}

.card-info-litle-title{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #444444;
    margin-top: 9px;
    text-align: left;
}

.card-info-pictures-bottom{
    display: flex;
    flex-direction: row;
}

.card-info-pictures-content-bottom{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
}

.card-info-data-pribadi-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    border-radius: 4px;
    width: 100%;
    padding: 28px 30px;
    margin-top: 20px;
}

.card-info-peserta-header{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #444444;
}

.card-info-view-input-wrap{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.card-info-view-input-wrap-one{
    // width: 25%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #999999;
    min-width: 270px;
    max-width: 270px;
    text-align: left;
}

.card-info-view-input-wrap-two{
    width: 50%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
    text-align: left;
}

.card-info-view-input-wrap-blue{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #3598DC;
    text-align: left;
    width: 70%;
}

.card-info-view-input-wrap-box-grey{
    width: 100%;
    background: #F4F7FC;
    border-radius: 4px;
    padding: 20px 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.card-info-text-editor-font{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
    line-height: 24px;
}

.title-grey-top-litle{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;
    color: #000000;
    font-weight: bold;
    text-align: left;
}

.wrapper-card-info-text-editor{
    margin-top: 10px;
    padding-left: 18px;
    width: 65%;
}

.list-item-font-style-card-info{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #999999;
    padding-left: 18px;
}

.wrapper-back-info-peserta{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pdf-text-editor{
    font-family: Roboto !important;
    font-style: normal;
    font-weight: normal;
    line-height: 25px;
    color: #000000;
    width: 100%;
    height: 100%;
    margin-top: 10px;
    text-align: left;
}

.pdf-text-editor-font{
    height: 100%;
    width: 100%;
}

.wrap-two-standin-131231{
    background-color: white;
    margin-left: 0px;
    border: 0px;
    padding: 28px 30px;
    padding-top: 0px;
    border-radius: 4px;
    display: none;
}

@media (max-width: 948px){
    .wrap-two-131231{
        display: none;
    }
    .wrap-two-standin-131231{
        width: 100%;
        display: flex;
    }
}