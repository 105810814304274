.tambah-chapter-wrap-btn{
    margin-left: auto;
}

.select-action-custom{
    box-sizing: border-box;
    border-radius: 4px;
    width: 92px;
    height: 32px;
    border: 1px solid #3598DC;
    box-sizing: border-box;
    transition: 0.5s;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3598DC;
    padding-left: 21px;
}

.select-action-custom:hover{
    color: white;
    background: #3598DC;
    border: none;
}

.select-action-custom:focus{
    outline: none;
}

.style-option-custom{
    height: 32px;
    width: 100%;
    padding: 10px 12px;
}

.aksi-placeholder{
    margin-top: -11px;
    color: '#3598DC',
}

.value-selectable-aksi{
    width: 100px !important;
    height: 32px !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #3598DC !important;
    justify-content: center !important;
    background-color: white !important;
    border-bottom: 0.5px solid #EBEBEB !important;
}

.value-selectable-aksi-red-mid{
    width: 100px !important;
    height: 32px !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #FF5579 !important;
    justify-content: center !important;
    background-color: white !important;
    border-bottom: 0.5px solid #EBEBEB !important;
}

.value-selectable-aksi-red{
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #FF5579 !important;
    justify-content: center !important;
    background-color: white !important;
}

.swal-text-custom-black{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    color: #444444;
}


.swal-text-custom-blue-big{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    color: #3598DC;
}

.swal-text-custom-grey{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #999999;
    width: 90%;
    text-align: center;
    margin: 0 auto;
}

.btn-delete-swal{
    background: #E70000;
    border-radius: 4px;
    width: 120px;
    height: 44px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    border: none;
    outline: none;
    margin-left: 20px;
    cursor: pointer;
    transition: 0.5s;
}

.btn-delete-swal:hover{
    background: #b80000;
}

.btn-cancel-swal{
    cursor: pointer;
    background: white;
    border-radius: 4px;
    width: 120px;
    height: 44px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #3598DC;
    border: 1px solid #3598DC;
    outline: none;
    transition: 0.5s;
}

.btn-cancel-swal:hover{
    background: #3598DC;
    color: white;
}

.divider-modal{
    background-color: #F4F4F4;
    width: 121%;
    margin-left: -45px;
    height: 1px;
    margin-top: 29px;
}

.swal-text-custom-red{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #E70000;
}

.swal-text-custom-blue{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #3598DC;
}

.swal2-show{
    margin-top: 75px;
    margin-left: 150px;
}

.swal-row{
    display: flex;
    align-items: center;
    width: 436px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.swal-row-flat{
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.times-custom{
    margin-top: 3px;
    margin-left: auto;
    cursor: pointer;
}

.pengaturan-umum-2021-wrapper{
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(117, 117, 117, 0.1);
    border-radius: 4px 4px 0px 0px;
    margin-top: 20px;
    width: 100%;
}

.filter-table{
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 200px;
    height: 44px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #C9C9C9;
    padding: 0px 14px;
}

.filter-table:focus{
    outline: none;
}

.filter-table::placeholder{
    color: #C9C9C9;
}

.search-filter{
    display: flex;
    flex-direction: row;
    padding: 0px;
    // border: none;
    align-items: center;
    width: 306px;
}

.daftar-component-wrapper{
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    margin-top: 20px;
}

.daftar-component-header{
    height: 60px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(117, 117, 117, 0.1);
    border-radius: 4px 4px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
}

.daftar-header-font{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    text-transform: uppercase;
    color: #444444;
}

.daftar-filter-table{
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.daftar-table-wrapper{
    width: 100%;
    padding: 30px 20px;
}



/* arrows */
select.filter-table {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../../../../images/dropdown.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) calc(1em + 2px);
}

.filter-table-date{
    display: flex;
    height: 44px;
}

.filter-table-date-style{
    // background: #FAFAFA;
    // border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 200px;
    height: 44px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #C9C9C9;
    // padding: 0px 14px;
}

.filter-table-date-style-2021{
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 200px;
    height: 44px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #C9C9C9;
    padding: 0px 14px;
    display: flex;
    align-items: center;
    
}

.daftar-table{
    width: 100%;
    margin-top: 13px;
}

.MuiTablePagination-selectRoot{
    margin-right: 0px !important;
}

.daftar-table .MuiTablePagination-actions{
    display: none !important;
}

.daftar-table .MuiToolbar-gutters{
    padding-left: 0px !important;
}

.pagination-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pagination-left{
    display: flex;
    justify-content: flex-start;
}

.btn-detail-pin{
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 4px;
    width: 52px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    transition: 0.5s;
}
.btn-detail-pin:hover{
    background: #3598DC;
    border-radius: 4px;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
}


.btn-detail-pin-tersedia{
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 4px;
    width: 52px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-custom-filter{
    color: #C9C9C9;
    font-size: 20px;
    margin-left: auto;
}

.btn-detail-pin:hover span {
    display:none;
}
.btn-detail-pin:hover:before{
    content:"Detail";
}

.container-print-btn{
    display: flex;
    flex-direction: row;
}

#myBtn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: #3597de;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 4px;
    transition: 0.5s;
}

#myBtn:hover {
background-color: rgb(10, 106, 180);
}