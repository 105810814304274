.ModalAlert-2 {
    position: fixed;
    z-index: 500;
    border-radius: 8px;
    // border: 1px solid #ccc;
    // box-shadow: 1px 1px 1px black;
    // padding: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

.Backdrop-2 {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.2);
}

.modal-contain-alert{
    display: flex;
    width: 550px;
    padding: 30px 30px;
    box-sizing: border-box;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    flex-direction: column;
}

.modal-alert-txt{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #2C304D;
}

.btn-modal-alert-wrapper{
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-top: 30px;
}

.input-register-box{
    border: 1px solid #BFBFBF;
    box-sizing: border-box;
    border-radius: 8px;
    height: 40px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.fa-eye-styles{
    margin-right: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 18px;
}

.fa-lock-styles{
    color: #C6C6C6;
    font-size: 20px;
}

.input-register{
    height: 70%;
    width: 100%;
    border: none;
    border-left: 0.5px solid #C5CDFF;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 0px 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.input-register:focus{
    outline: none;
}

.input-register::placeholder{
    color: #B4B4B4;
}

@media (max-width: 575px){
    .modal-contain-alert{
        width: 450px;
        padding: 30px 30px;
    }
}

@media (max-width: 454px){
    .modal-contain-alert{
        width: 350px;
        padding: 20px 20px;
    }
}

@media (max-width: 360px){
    .modal-contain-alert{
        width: 300px;
        padding: 20px 10px;
    }
}