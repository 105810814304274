.select-tabs{
    text-transform: none !important;
    height: 80px;
    width: 255px ;
    font-size: 13px;
    line-height: normal;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 21px !important;
    text-align: center ;
    color: #3598DC !important;
}

.select-tabs-disabled{
    text-transform: none !important;
    width: 255px ;
    height: 80px !important;
    font-size: 13px !important;
    line-height: normal !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 21px !important;
    text-align: center ;
    color: #b1b1b1 !important;
}

@media (max-width: 1440px) {
    .select-tabs{
        width: 200px ;
    }
    
    .select-tabs-disabled{
        width: 200px ;
    }
}

@media (min-width: 600px) {
    .MuiTab-root {
        min-width: 0px !important; 
    }
}

.MuiPaper-elevation4{
    box-shadow: none !important;
}

.penerbitan-pin-content-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
    box-sizing: content-box;
}

.content-parent-wrapper-2021{
    width: 100%;
    margin-top: 20px;
}

.penerbitan-pin-content-left{
    display: flex;
    flex-direction: column;
    width: 58%;
    background-color: white;
    border-radius: 4px;
}

.penerbitan-pin-content-right{
    display: flex;
    flex-direction: column;
    width: 42%;
    background-color: white;
    border-radius: 4px;
    padding: 20px 20px;
    margin-left: 10px;
    box-sizing: border-box;
}

.penerbitan-content-left-header{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    color: #444444;
    box-shadow: 0px 1px 2px rgba(117, 117, 117, 0.1);
    border-radius: 4px 4px 0px 0px;
    min-height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
}

.penerbitan-content-input-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    padding-top: 33px;
    padding-bottom: 33px;
}

.penerbitan-content-input{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.penerbitan-input-font{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #666666;
    width: 30%;
    min-width: 134px;
    margin-right: 30px;
}

.penerbitan-input{
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 476px;
    height: 44px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #C9C9C9;
    padding: 0px 16px;
    margin-right: 20px;
}

.penerbitan-input:focus{
    outline: none;
}

.penerbitan-input::placeholder{
    color: #C9C9C9;
}

/* arrows */
select.penerbitan-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../../../../images/dropdown.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) calc(1em + 2px);
}

.penerbitan-date{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 476px;
    margin-right: 20px;
}

.penerbitan-calendar-style{
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    height: 44px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #C9C9C9;
    padding: 0px 16px;
    display: flex;
}

.penerbitan-calendar-style-datepicker{
    width: 100%;
    text-align: left;
}

.react-date-picker__wrapper{
    border: none !important;
}

.penerbitan-date-info{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #999999;
    margin-top: 13px;
    text-align: left;
    box-sizing: border-box;
    border-radius: 4px;
    width: 476px;
}

.penerbitan-logo-right{
    background: rgba(53, 152, 220, 0.1);
    border-radius: 10px;
    padding: 10px 12px;
    display: flex;
    justify-content: center;
    margin-right: 21px;
}

.penerbitan-right-content{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.penerbitan-right-font-header{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #3598DC;
}

.penerbitan-right-font{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #999999;
    text-align: left;
    margin-top: 10px;
}

.divider{
    width: 100%;
    background-color: #E9E9E9;
    height: 1px;
    margin: 12px 0px ;
}

@media (max-width: 1155px){
    .penerbitan-pin-content-wrapper{
        flex-direction: column;
        margin-top: 20px;
    }
    .penerbitan-pin-content-right{
        width: 100%;
        margin-top: 10px;
        margin-left: 0px;
    }
    .penerbitan-pin-content-left{
        width: 100%;
    }
}