.modal-nilai-wrapper{
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    padding-bottom: 45px;
    border-radius: 10px;
}

.modal-nilai-text{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #444444;
}

.modal-nilai-data-nama{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #444444;
}

.modal-nilai-grey-box{
    background: #FAFAFA;
    border: 1px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 8px;
    width: 454px;
    margin-top: 28px;
    padding-bottom: 30px;
}

.modal-nilai-grey-header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 12px;
    border-bottom: 1px solid #D1D1D1;
    padding: 13px 19px;
}

.modal-nilai-header-title-number{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    color: #3598DC;
    margin-left: 25px;
}

.modal-nilai-header-title{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #999999;
    margin-top: -5px;
}

.modal-content-wrapper{
    padding: 24px 19px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 0px;
}

.modal-nilai-content{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #444444;
    margin-top: 11px;
    text-align: left;
    max-height: 150px;
    overflow-y: auto;
    width: 100%;
    // padding-right: 20px;
}

.rodal-dialog{
    border-radius: 10px !important;
    height: 10% !important;
    margin-top: 10% !important;
    background-color: none !important;
    padding: 0px !important;
}

.modal-nilai-content::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.modal-nilai-content::-webkit-scrollbar{
	width: 5px;
	background-color: #F5F5F5;
}

.modal-nilai-content::-webkit-scrollbar-thumb{
	background-color: #0ae;
	background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, rgba(255, 255, 255, .2)), color-stop(.5, transparent), to(transparent));
}

.modal-nilai-transparent-box{
    width: 454px;
    margin-top: 28px;
}

.dinilai-oleh{
    margin-top: 15px;
    margin-right: auto;
    margin-left: 50px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #444444;
}


.title-m-berkas{
    display: flex;
    justify-content: space-between;
    padding: 13px 20px;
    border-bottom: 1px solid #DDDDDD;
    border-top: 1px solid #DDDDDD;
    align-items: center;
    .title-berkas-modal{
        font-size: 18px;
        line-height: 21px;
        color: #000000;
    }
}

.btn-otoritas.btn-otoritas{
    padding: 0;
    text-transform: none;
    box-shadow: none;
    width: 118px;
    height: 34px;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: white;
    &:hover,
    &:focus,
    &:active{
        box-shadow: none;
    }
}


.txt-ttl-berkas{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
}

.div-berkas-blue{
    max-height: 75px;
    min-height: 75px;
    display: flex;
    align-items: center;
    background: #F5FBFF;
    border: 1px solid #B9DCF4;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 14px 10px;
    &:hover{        
        box-shadow: 2px 6px 12px rgba(170, 170, 170, 0.2);
    }
    .txt-name{
        margin-top: 4px;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #3598DC;
    }
}

.div-berkas-red{
    max-height: 75px;
    min-height: 75px;
    display: flex;
    align-items: center;
    background: #F7E6E6;
    border: 1px solid #F4BBBB;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 14px 10px;
    &:hover{        
        box-shadow: 2px 6px 12px rgba(170, 170, 170, 0.2);
    }
    .txt-name{
        margin-top: 7px;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #AA0505;
    }
    .no-berkas{
        margin-top: 4px;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        color: #F87777;
    }
}

.txt-upload{
    margin-top: 5px;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #707070;
}

.txt-act-berkas{
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
}

.div-chapter-modal{
    justify-content: space-between !important;
}

.btn-upload-modal-berkas{
    width: 94px;
    height: 22px;
    background: #00B268;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #FFFFFF;
}

.more-modal.more-modal{
    width: 18px;
    height: 18px;
    color: #969696;
    cursor: pointer;
}

.div-oto-diberi{
    display: flex;
    align-items: center;
    padding-left: 11px;
    width: 168px;
    height: 34px;
    background: #00B268;
    border-radius: 4px;
    color: white !important;
    font-size: 14px;
    line-height: 16px;
    justify-content: space-between;
    cursor: pointer;
}

.div-oto-arr{
    border-left: 1px solid rgba(255, 255, 255, 0.22);
    align-items: center;
    display: flex;
    justify-content: center;
    width: 33px;
    height: 100%;
}

.txt-modal-wawancara{
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #222222;
    margin-top: 33px;
    margin-bottom: 55px;
}

.txt-modal-nilai{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #222222;
    width: 393px;
    margin-top: 20px;
}

.btn-modal-wawancara{
    border-radius: 4px;
    height: 44px;
    cursor: pointer;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF !important;
    text-decoration: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 15px;
}

.btn-green{
    background: #00B268;
}

.btn-blue{
    background: #3598DC;
}

.upload-image__file:hover ~ .upload-image__overflow{
    opacity:0.8
}
.upload-image__file{
    background-color: blue;
    z-index:99;
    position:absolute;
    opacity:0;
    cursor:pointer
}

.cek-nilai{
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #666666;
    margin-top: 11px;
    margin-bottom: 40px;
    width: 335px;
}

.con-mo-nilai{
    // width: 500, padding: '40px 0px', textAlign: 'center',
    width: 500px;
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.title-modal-keterangan-modal{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #222222;
}

.textbox-modal-keterangan-modal{
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 15px;
    box-sizing: border-box;
    margin-top: 18px;
}

.textbox-modal-keterangan-modal:focus{
    outline: none;
    border: 1px solid #3598DC;
}

.textbox-modal-keterangan-modal::placeholder{
    color: #C9C9C9;
}

.wrapper-btn-modal-keterangan-modal{
    display: flex;
    margin-top: 20px;
}

.blue-btn-modal-keterangan-modal{
    background: #3598DC;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    padding: 9px 14px;
    margin-right: 14px;
    cursor: pointer;
    transition: 0.5s;
}

.blue-btn-modal-keterangan-modal:hover{
    background: #1d71a8;
}

.grey-btn-modal-keterangan-modal{
    background: #E7E7E7;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #444444;
    padding: 9px 30px;
    cursor: pointer;
    transition: 0.5s;
}

.grey-btn-modal-keterangan-modal:hover{
    background: #c7c6c6;
}

.title-modal-custom{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #222222;
    margin-top: 20px;
}

.text-modal-custom{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #666666;
    width: 400px;
    margin: 0 auto;
    margin-top: 12px;
    justify-content: center;
    align-items: center;
}

.button-wrapper-modal-custom{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
}

.button-confirm-modal-custom{
    background: #3598DC;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    height: 44px;
    min-width: 125px;
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
}

.button-confirm-modal-custom:hover{
    background: #4aa6e4;
}

.button-cancel-modal-custom{
    background: #E7E7E7;
    border-radius: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #444444;
    height: 44px;
    width: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
}

.button-cancel-modal-custom:hover{
    background: #d1d1d1;
}