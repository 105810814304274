// PIN
.wrapping-card-pin-09830913 {
  width: 95%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.pin-card-0739173912 {
  display: inline-block;
  margin: 10px;
  width: 283px;
  max-height: 114px;
  min-height: 114px;
  background: white;
  border: 1px solid #3598DC;
  border-radius: 4px;
  box-sizing: border-box;
}

.pin-card-header {
  background: #3598DC;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
  min-height: 26px;
  max-height: 26px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
}

.container-print-pin-087913 {
  width: 100%;
  display: table;
  overflow: hidden;
  -webkit-print-color-adjust: exact;
}

.container-page-pin-31038103 {
  width: 100%;
}

.pin-content-381098301 {
  display: flex;
  flex-direction: row;
  height: calc(114px - 26px);
  padding: 11px 11px;
  box-sizing: border-box;
}

.pin-left-content-309197391 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.pin-style {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #444444;
}

.exp-pin-grey {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 9px;
  text-align: left;
  color: #999999;
}

.exp-pin-black {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  text-align: left;
  line-height: 9px;
  color: #444444;
}

.footer-pin-wraper-309183019 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 5px;
  line-height: 6px;
  color: #444444;
  margin-top: auto;
  text-align: left;
}

.footer-pin-wraper {
  display: flex;
  align-items: flex-end;
  margin-top: auto;
}

.logo-binabud-cirle-131312 {
  width: 50px;
  margin-top: -5px;

}

.pin-right-content-31203123 {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #3599dc83;
  padding-left: 10px;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 70px;
  min-width: 70px;
}

.pin-chapter-name-313131 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  color: #3598DC;
  display: flex;
  text-align: center;
}