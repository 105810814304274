.tambah-chapter-wrap-btn{
    margin-left: auto;
}

.select-action-custom{
    box-sizing: border-box;
    border-radius: 4px;
    width: 92px;
    height: 32px;
    border: 1px solid #3598DC;
    box-sizing: border-box;
    transition: 0.5s;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3598DC;
    padding-left: 21px;
}

.select-action-custom:hover{
    color: white;
    background: #3598DC;
    border: none;
}

.select-action-custom:focus{
    outline: none;
}

.style-option-custom{
    height: 32px;
    width: 100%;
    padding: 10px 12px;
}

.aksi-placeholder{
    margin-top: -11px;
    color: '#3598DC',
}

.box-white-download-berkas{
    margin: 6px 6px;
    background: #FFFFFF;
    border: 0.5px solid #E8E7E7;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(117, 117, 117, 0.1);
    border-radius: 10px;
    max-width: 286px;
    width: 286px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    padding-bottom: 18px;
}

.box-white-blue-download-berkas{
    background: #F1F9FF;
    border-radius: 10px;
    width: 100%;
    height: 215px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box-white-blue-download-berkas-text-dwd{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    margin-top: 19px;
    color: #3598DC;
    width: 70%;
}