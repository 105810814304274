
.container-print {
    overflow: hidden;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    -webkit-print-color-adjust: exact; 
}

.btn-print {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 40px;
    text-align: center;
    font-size: 16px;
    line-height: 45px;
    color: #EAEAEA;
    background: linear-gradient(90deg, #00B5AE 0%, #02739F 96.82%);
    border-radius: 4px;
    cursor: pointer;
} 

.container-page-card{
    padding-top: 34px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-page{
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.container-header-print{
    margin-top: 60px;
    width: 100%;
}

.container-header-print-afs-new{
    margin-top: 60px;
    width: 70%;
}

.container-header-print-afs{
    width: 100%;
}

.container-header-print-card{
    padding-top: 10px;
    margin-right: auto;
}

.container-header-print-kakehashi{
    width: 95%;
    padding-top: 80px;
}

.page1-font1{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    color: #3598DC;
    text-align: center;
}

.page1-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.container-header-print-image-page1{
    background-image: url('../images/no-user-image-icon-18.jpg');
    width: calc(472px / 3);
    height: calc(709px / 3);
    border-radius: 4px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 33px;
    margin-bottom: 33px;
}

.page1-name{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    text-transform: uppercase;
}

.page1-no-name{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #000000;
    margin-top: 9px;
}

.divider-page1-pdf{
    width: 100%;
    border-top: 2px solid #F0F0F0;
}

.page1-custom-bottom-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.page1-custom-container{
    display: flex;
    flex-direction: column;
    width: calc(100% / 3);
}

.page1-custom-blue{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #3598DC;
}

.page1-custom-black{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
}

.info-person-btn-fake{
    border: 1px solid #3598DC;
    box-sizing: border-box;
    border-radius: 2px;
    // width: 200px;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #3598DC;
    display: flex;
    align-items: center;
    justify-content: center;
}

.divider-page2-pdf{
    border-top: 6px solid #3598DC;
    width: 100%;
}

.font-header-page2{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    text-align: left;
    width: 100%;
}

.page2-header-wrapper{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}

.page2-content-wrapper{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
}

.page2-row{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.page2-row-left{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #3598DC;
    max-width: 200px;
    min-width: 200px;
}

.page2-row-right{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    width: 60%;
    text-align: left;
}

.divider-page2-pdf-grey{
    width: 100%;
    border-top: 1px solid #F0F0F0;
}

.font-header-page3{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    width: 100%;
    text-align: left;
}

.table-style-custom-pdf{
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
}

.table-style-custom-pdf, th, td {
    border: 1px solid #F0F0F0;
    border-collapse: collapse;
    border-radius: 2px;
    padding: 5px;
    text-align: left;
    padding-left: 10px;
}

.table-style-custom-pdf-page5{
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
}

.table-style-custom-pdf-page5, th, td {
    border: 1px solid #F0F0F0;
    border-collapse: collapse;
    border-radius: 2px;
    padding: 5px;
    padding-left: 10px;
}

.table-style-custom-pdf-page5 th {
    text-align: left;
}

.table-style-custom-pdf-page5 td {
    text-align: center;
}

.table-header-pdf{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #3598DC;
}

.table-td-pdf{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
}

.pdf-text-editor{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    width: 100%;
    height: 100%;
    margin-top: 5px;
}

.pdf-text-editor-font{
    height: 100%;
    width: 100%;
}

.ttd-wrapper-pdf{
    width: 50%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
}

.ttd-wrapper-pdf-txt{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}

.ttd-row-pdf{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 15px;
}

.line-ttd{
    border-bottom: 1px solid #000000;
    width: 40%;
    margin-right: 5px;
}

.ttd-text-pdf{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    flex-wrap: nowrap;
}

.line-ttd-blue{
    width: 100%;
    border: 1px solid #3598DC;
    margin-top: 80px;
}

// PIN
.wrapping-card-pin{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-height: 100vh;
}

.pin-card{
    margin-top: 14px;
    width: 283px;
    height: 114px;
    background: white;
    border: 1px solid #3598DC;
    border-radius: 4px;
}

.pin-card-header{
    background: #3598DC;
    // border-radius: 4px 4px 0px 0px;
    width: 100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 11px;
    height: 26px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-print-pin {
    max-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    -webkit-print-color-adjust: exact; 
}

.container-page-pin{
    width: 85%;
    max-height: 100vh;
    min-height: 100vh;
}

.pin-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 8px;
    padding-left: 11px;
    padding-right: 11px;
    padding-bottom: 8px;
    height: 114px - 45px;
}

.pin-left-content{
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.pin-style{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #444444;
}

.exp-pin-grey{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 9px;
    color: #999999;
}

.exp-pin-black{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;    
    font-size: 8px;
    line-height: 9px;
    color: #444444;
}

.pin-footer{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 5px;
    line-height: 6px;
    color: #444444;
    margin-top: 20px;
}

.footer-pin-wraper{
    display: flex;
    align-items: flex-end;
    margin-top: auto;
}

.logo-binabud-cirle{
    width: 50px;
    margin-top: -5px;
}

.pin-right-content{
    display: flex;
    flex-direction: column;
    border-left: 1px solid #3599dc83;
    align-items: center;
    justify-content: center;
    padding-left: 15px;

}

.pin-chapter-name{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    line-height: 9px;
    color: #3598DC;
}

.wrapper-card-double{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.wrapper-card-left{
    width: 60%;
    display: flex;
    flex-direction: column;
}

.wrapper-card-right{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: auto;
}

.wrapper-card-wrapper{
    display: flex;
    flex-direction: column;
}

.wrapper-card-wrapper-left{
    display: flex;
    margin-right: auto;
    flex-direction: column;
    margin-top: 30px;
    justify-content: flex-start;
    align-items: flex-start;
}

.wrapper-card-wrapper-blue{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #3598DC;
}

.wrapper-card-wrapper-black{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 14px;
    /* identical to box height */
    color: #000000;
}

.wrapper-containt{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.wrapper-containt-black-bold{
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 21px;
    color: #000000;
}

.wrapper-containt-blue{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 12px;
    color: #3598DC;
}

.wrapper-containt-black{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    // line-height: 12px;
    color: #000000;
}

.wrapper-containt-row{
    display: flex;
    // justify-content: space-between;
    width: 100%;
}

.alert-card{
    margin-top: auto;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #3598DC;
}

.container-card-photo{
    width: calc(472px / 3);
    height: calc(709px / 3);
}

.box-card-ttd{
    width: calc(472px / 3);
    height: 68px+20px;
    border: 0.5px solid #3598DC;
    box-sizing: border-box;
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    font-size: 8px;
    line-height: 9px;
    color: #3598DC;
    display: flex;
    align-content: center;
    justify-content: center;
}

.cut-here{
    margin-top: 50px;
    margin-bottom: 5px;
    width: 100%;
    border: 1.5px dashed #000000;
}

.petunjuk-card{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
}

.petunjuk-card-point{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
}

.cut-here-text{
    font-family: Roboto;
    font-style: italic;
    font-weight: 300;
    font-size: 8px;
    line-height: 9px;
    text-align: center;
    color: #000000;
    margin-left: auto;
}


.page2-row-new{
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid #F0F0F0;
    padding-bottom: 5px;
    padding-top: 5px;
}

.page2-row-left-new{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #3598DC;
    max-width: 200px;
    min-width: 200px;
    text-align: left;
}

.page2-row-right-new{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    width: 60%;
    text-align: left;
}

.wrapper-content-86831{
    width: 100%;
    text-align: left;
}