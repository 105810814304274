@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');


.ml-5{
  margin-left: 5px !important;
}

.ml10{
  margin-left: 5px !important;
}

.w50{
  width: 50%;
}

.ml20{
  margin-left: 20px;
}

.capitalize{
  text-transform: capitalize !important;
}

.p20{
  padding: 20px;
  width: 100%;
}

.mt12{
  margin: 0px auto;
  margin-top: 12px;
  width: 100%;
}

.mt40{
  margin-top: 40px;
}

.mt15{
  margin-top: 15px;
}

.mr15{
  margin-right: 15px;
}

.mr16{
  margin-right: 16px;
}

.mt10{
  margin-top: 10px;
}

.mb10{
  margin-bottom: 10px;
}

.mt25{
  margin-top: 25px;
}

.ta-left{
  text-align: left !important;
}

.ta-right{
  text-align: right !important;
}

.pt10{
 padding-top: 10px;
}

.mt6{
  margin-top: 6px;
}

.mt60{
  margin-top: 60px;
}

.mx50{
  margin-left: 50px;
  margin-right: 50px;
}

.mt56{
  margin-top: 56px;
}

.mt30{
  margin-top: 30px;
}

.mt35{
  margin-top: 35px;
}

.mt47{
  margin-top: 47px;
}

.pb47{
  padding-bottom: 47px;
}

.mb20{
  margin-bottom: 20px;
}

.mb10{
  margin-bottom: 10px !important;
}

.menu-center{
  text-align: center !important;
  justify-content: center !important;
  padding-left: 0px !important;

}

.flex{
  display: flex !important;
}

.mb12{
  margin-bottom: 12px;
}

.App {
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.p4{
  padding: 4px !important;
}

.p412{
  padding: 4px 12px !important;
}

.t-no-wrap{
  white-space: nowrap !important;
}

.flex{
  display: flex !important;
}

.Pagination-button-78.Pagination-button-78{
  width: 30px;
  height: 30px;
  margin: 0px 3px;
  border: 0.5px solid #D4D4D4;
  border-radius: 2px;
  color: #444444;
  font-weight: normal;
}

.mx10{
  margin-left: 10px;
  margin-right: 10px;
}

.TableHeaderCell-cell-42:first-child.TableHeaderCell-cell-42:first-child{
  padding-left: 0px;
}

.PageSizeSelector-pageSizeSelector-70.PageSizeSelector-pageSizeSelector-70{
  padding-right: 5px;
}

.PageSizeSelector-label-71.PageSizeSelector-label-71{
  padding-right: 5px;
}

.Pagination-arrowButton-80.Pagination-arrowButton-80{
  width: 30px;
  height: 30px;
  margin: 0px 5px;
  border: 0.5px solid #D4D4D4;
  border-radius: 2px;
  padding: 0px;
}

.Pagination-activeButton-79.Pagination-activeButton-79{
  background-color: #F5F5F5;
  font-weight: normal;
}

.MuiButton-root.Mui-disabled.MuiButton-root.Mui-disabled{
  border: none;

}

.PageSizeSelector-inputRoot-73.PageSizeSelector-inputRoot-73{
  text-align: center;
  border: 0.5px solid #D4D4D4;
  border-radius: 2px;
}

.Pager-pager-69.Pager-pager-69{
  justify-content: flex-start;
  color: #999999;
}

.Pagination-rowsLabel-77.Pagination-rowsLabel-77{
  padding-right: 15vw;
  color: #999999;
}

.bg-blue{
  background-color: #3598DC;
}

.c-blue{
  color: #3598DC;
}

.bg-red{
  background-color: #DE0505;
}

.c-red{
  color: #DE0505;
}