.dashboard-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.dashboard-box-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
}

.dashboard-box-column-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.box-white-dashboard-big {
  background: #FFFFFF;
  border-radius: 4px;
  width: 50%;
  height: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.box-white-dashboard-big3 {
  background: #FFFFFF;
  border-radius: 4px;
  width: 33.3%;
  height: 280px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
}

.box-white-header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 38px;
}

.header-text-font-box-white {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #444444;
  text-align: left;
}

.box-white-dashboard-triple {
  width: 402px;
  height: 286px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
}

.header-text-font-box-white-triple {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #444444;
  text-align: left;
  width: 60%;
}

.box-white-header-wrapper-triple {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.box-white-dashboard-double-big {
  width: 50%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 20px;
}

.box-white-dashboard-double-transparent {
  width: 613px;
  height: 491px;
  border-radius: 4px;
  padding: 20px;
}

.box-white-dashboard-duo {
  background: #FFFFFF;
  width: 295px;
  height: 239px;
  border-radius: 4px;
  padding: 20px;
}

.box-white-dashboard-duo-wide {
  min-height: 232px;
  max-height: 232px;
  border-radius: 4px;
  padding: 20px;
  background: #FFFFFF;
}

.little-box-wrapper-info {
  // max-width: 613px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  // width: 90%;
}

.card-big-dashboard {
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}

.card-big-dashboard-yes {
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}

.card-big-header-font-big {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  color: #3835DC;
}

.card-big-header-font-puple {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3835DC;
}

.card-standard-dashboard {
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 20px;
}

.card-standard-header-font-big {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
}

.card-standard-header-font-grey {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
}

.card-box-litle-color {
  background: #FC8800;
  border-radius: 10px;
  width: 181px;
  // height: 160px;
  padding: 15px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.card-box-litle-color-bottom {
  background: rgba(255, 255, 255, 0.12);
  border-radius: 8px;
  height: 48px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  align-items: center;
  justify-content: space-between;
}

.card-box-litle-color-one {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
}

.card-box-litle-color-two {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #EAEAEA;
  text-align: left;
  margin-top: 5px;
}

.card-box-litle-color-bottom-one {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #EAEAEA;
  margin-left: auto;
}

.card-box-litle-color-bottom-two {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #EAEAEA;
  text-align: center;
}

.card-box-semi-wide {
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  width: 280px;
  height: 165px;
  padding: 20px;
}

.card-box-semi-wide-white {
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 4px 8px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  width: 280px;
  height: 165px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-box-semi-wide-purple {
  background: #C900FC;
  border-radius: 10px;
  width: 280px;
  height: 165px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-box-semi-wide-blue {
  background: #6F00FC;
  border-radius: 10px;
  width: 280px;
  height: 165px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-box-semi-wide-orange {
  background: #FC8800;
  border-radius: 10px;
  width: 280px;
  height: 165px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-box-semi-wide-card {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  width: 100%;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-box-semi-wide-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #EAEAEA;
}

.card-box-font-header-wide {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  color: #3835DC;
}

.card-box-font-title-wide {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #3835DC;
}

.dashboard-box-wrapper-semi {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-box-semi-wide-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 10px;
}

.doughnout-wrapper {
  display: flex;
  flex-direction: row;
}

.doughnout-wrapper-new-1231 {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 10px;
}

.doughnout-wrapper-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -140px;
}

.doughnout-wrapper-text-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 60px;
  width: 100%;
}

.doughnout-wrapper-text-info-top {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  align-items: flex-end;
}

.doughnout-percentange-blue-txt {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  color: #3835DC;
}

.doughnout-percentange-grey-txt {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #888888;
  width: 100px;
}

.doughnout-blue-txt {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  color: #3835DC;
  padding-bottom: 5px;
  width: 130px;
}

.doughnout-blue-num {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: #3835DC;
}

.doughnout-grey-bottom-txt {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #888888;
  // width: 335px;
  text-align: left;
}

.box-myChartPie {
  display: flex;
  margin-top: 10px;
  // width: 50%;
  align-items: center;
}

.box-white-dashboard-duo-text-wraper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.info-jenis-kelamin {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3598DC;
}

.info-box-jenis-kelamin {
  background: #EFF3F8;
  border-radius: 2px;
  width: 134px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bubble-info {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.bubble-info-txt {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #888888;
}

.bubble-info-box-jenis-kelamin {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.chart-wide-info-box {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.info-jumlah-pendaftar {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  text-align: right;
  color: #666666;
  transform: rotate(-90deg);
}

.wrapping-chart-wide {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  width: 100%;
}


// -------------------------------------------------- NEW

.container-dashboard-new-3131 {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  width: 100%;
}

.container-dashboard-new-row-0880 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex: 1;
}

.box-double-new-1312 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  justify-content: space-between;
}

.box-double-new-left-1312 {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  justify-content: space-between;
}

.box-double-new-left-yes {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.card-big-new-ds-23123 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.middle-card-big {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  // flex-wrap: wrap;
}

.middle-card-big-yes {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.middle-card-big-yes-num {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: #FFFFFF;
  max-width: 100px;
  min-width: 100px;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.middle-card-big-yes-txt {
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  color: #FFFFFF;
  justify-content: flex-start;
  align-items: flex-start;
}

.middle-card-big-yes-txt-98214 {
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 15px;
  color: #FFFFFF;
  justify-content: flex-start;
  align-items: flex-start;
}

.middle-card-big-num {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF;
  margin-right: 17px;
}

.middle-card-big-txt {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #EAEAEA;
  text-align: left;
}

.middle-card-big-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
}

.middle-card-big-container-yes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.chart-txt {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #666666;
  margin-top: 10px;
}

.chart-txt-vert {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #666666;
  margin-top: 10px;
  writing-mode: vertical-lr;
  -webkit-transform: rotate(180deg);
  margin: auto 0;
}

.container-txt-left {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.loading-dasboard-23123 {
  display: flex;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 50vh;
  margin: 0 auto;
  flex: 1;
  align-self: 'center';
  margin-top: 20px;
}

.counting-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.btn-counting {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
}

.dashboard-2021-wpapper-row-one {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.dashboard-2021-wpapper-row-two-parent {
  display: flex;
  width: 100%;
  gap: 20px;
  margin: 20px 0px;
}

.dashboard-2021-wpapper-row-two-parent-child {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dashboard-2021-whitebox-header-sub-child {
  display: flex;
  width: 100%;
}

.dashboard-2021-whitebox-header-parent {
  background: #FFFFFF;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #18191F;
  min-height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  text-transform: uppercase;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #DDDDDD;
}

.dashboard-2021-whitebox-header-child {
  display: flex;
  gap: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #18191F;
  min-height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: 1px solid #DDDDDD;
  width: calc(100% / 3);
}

.dashboard-2021-whitebox-header-child-2{
  display: flex;
  gap: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #18191F;
  min-height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: 1px solid #DDDDDD;
  width: calc(100% / 2);
}

.dashboard-2021-wpapper-row-two {
  width: 100%;
  min-width: 250px;
  background: #FFFFFF;
  border-radius: 4px;
}

.dashboard-2021-wpapper-row-three {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.dashboard-2021-whitebox-rowone {
  background: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  margin-right: 8px;
}

.dashboard-2021-wpapper-header-dom{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .dashboard-2021-wpapper-header-dom-text{
    display: flex;
    flex-direction: column;
    line-height: 5px;
    align-items: flex-end;
  }
}

.dashboard-2021-whitebox-rowone-non {
  background: #FFFFFF;
  border-radius: 4px;
  width: 100%;
}

.dashboard-2021-whitebox-rowone-col2 {
  background: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  margin-right: 8px;
  margin-left: 8px;
}

.dashboard-2021-whitebox-rowone-col3 {
  background: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  margin-left: 8px;
}

.dashboard-2021-whitebox-rowone-col3-non {
  background: #FFFFFF;
  border-radius: 4px;
  width: 100%;
  margin-left: 8px;
}

.dashboard-2021-whitebox-rowthree-1 {
  background: #FFFFFF;
  border-radius: 4px;
  width: calc(100% / 2);
  margin-right: 10px;
}

.dashboard-2021-whitebox-rowthree-2 {
  background: #FFFFFF;
  border-radius: 4px;
  width: calc(100% / 3);
  margin-left: 10px;
  margin-right: 10px;
}

.dashboard-2021-whitebox-rowthree-3 {
  background: #FFFFFF;
  border-radius: 4px;
  width: calc(100% / 2);
  margin-left: 10px;
}

.download-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  align-items: first baseline;
}

.dashboard-2021-whitebox-header {
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #DDDDDD;
  background: #FFFFFF;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #18191F;
  min-height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  text-transform: uppercase;
  text-align: left;
}

.dashboard-2021-whitebox-body {
  background: #FFFFFF;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 0px 0px 4px 4px;
}

.dashboard-2021-whitebox-body-counting-top {
  display: flex;
  width: 100%;
  align-items: flex-end;
}

.dashboard-2021-whitebox-body-counting-top-number {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 35px;
  color: #444444;
}

.dashboard-2021-whitebox-body-counting-top-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #444444;
  margin-left: 20px;
}

.dashboard-2021-whitebox-body-grey {
  background: #FAFAFA;
  border: 1px solid #F1F1F1;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 20px;
}

.dashboard-2021-whitebox-body-grey-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 13px 15px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #F1F1F1;
}

.dashboard-2021-whitebox-body-grey-content-right {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #444444;
}

.dashboard-2021-whitebox-body-grey-content-left {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #444444;
  margin-left: auto;
}

.row-3-whitebox-standin-non {
  display: none;
}

.row-3-whitebox-standin {
  display: none;
}

.row-2-whitebox-standin {
  display: none;
}

.dashboard-2021-whitebox-header-row3-chart {
  padding: 20px;
  border-radius: 0px 0px 4px 4px;
  box-sizing: border-box;
  width: 100%;
  height: 350px;
}

.dashboard-2021-whitebox-header-row3-chart-y {
  padding: 20px;
  border-radius: 0px 0px 4px 4px;
  box-sizing: border-box;
  width: 100%;
}


.dashboard-2021-whitebox-header-row2-body {
  padding: 20px;
  border-radius: 0px 0px 4px 4px;
  display: flex;
  flex-direction: row;
}

.dashboard-2021-whitebox-header-row2-body-standin {
  border-radius: 0px 0px 4px 4px;
  display: flex;
  flex-direction: row;
}

.dashboard-2021-colorbox-row2-1 {
  background: #FC8800;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
}

.dashboard-2021-colorbox-row2-2 {
  background: #00D67D;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.dashboard-2021-colorbox-row2-3 {
  background: #6F00FC;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.dashboard-2021-colorbox-row2-4 {
  background: #B007DA;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
}

.dashboard-2021-colorbox-content1-row2-1 {
  text-align: left;
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #FFFFFF;
}

.dashboard-2021-colorbox-content-row2-1 {
  text-align: left;
  padding: 14px 20px;
  display: flex;
  flex-direction: column;
}

.dashboard-2021-colorbox-row2-1-number {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #FFFFFF;
}

.dashboard-2021-colorbox-row2-1-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  margin-top: 5px;
}

.row-4-colorbox-standin {
  display: none;
}

.row-3-colorbox-standin {
  display: none;
}

.row-2-colorbox-standin {
  display: none;
}

.dashboard-2021-whitebox-pie-chart {
  width: 50%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dashboard-2021-whitebox-pie-chart-wrap {
  width: 100%;
  display: flex;
}

.dashboard-2021-whitebox-pie-chart-info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-left: 30px;
}

.dashboard-2021-whitebox-pie-chart-info-bluebox {
  background-color: #EFF3F8;
  border-radius: 2px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3598DC;
  padding: 11px 12px;
  width: fit-content;
  margin-bottom: 20px;
}

.dashboard-2021-whitebox-pie-chart-info-radio {
  width: 100%;
  display: flex;
  align-items: center;
}

.row-3-whitebox-chart-standin {
  display: none;
}

.row-2-whitebox-chart-standin {
  display: none;
}

.row-2-whitebox-chart-standin-wrap {
  display: none;
}

@media (max-width: 1468px) {
  .dashboard-2021-whitebox-pie-chart-info {
    padding-left: 20px;
  }
}

@media (max-width: 1448px) {
  .dashboard-2021-whitebox-pie-chart-info {
    padding-left: 10px;
  }
}

@media (max-width: 1403px) {
  .dashboard-2021-whitebox-header-row2-body-standin {
    padding: 0px 20px 20px 20px;
  }

  .row-4-colorbox {
    display: none;
  }

  .row-4-colorbox-standin {
    display: block;
  }

  .row-3-colorbox {
    display: none;
  }

  .row-3-colorbox-standin {
    display: block;
  }

  .dashboard-2021-colorbox-row2-3 {
    margin-left: 0px;
  }

  .dashboard-2021-colorbox-row2-2 {
    margin-right: 0px;
  }

  .dashboard-2021-whitebox-pie-chart-wrap {
    width: 100%;
    display: flex;
  }

  .dashboard-2021-whitebox-pie-chart {
    width: 50%;
  }
}

@media (max-width: 1360px) {
  .row-3-whitebox-standin {
    display: block;
  }

  .row-3-whitebox {
    display: none;
  }

  .dashboard-2021-whitebox-rowone-col3 {
    margin-left: 0px;
    margin-top: 16px;
  }

  .dashboard-2021-whitebox-rowone-col2 {
    margin-right: 0px;
    margin-left: 8px;
  }
}

@media (max-width: 1181px) {
  .row-2-whitebox-chart {
    display: none;
  }

  .row-2-whitebox-chart-standin {
    display: block;
  }

  .row-2-whitebox-chart-standin-shadows {
    display: none;
  }

  // .dashboard-2021-whitebox-rowthree-1{
  //     width: 100%;
  //     margin-right: 0px;
  // }
  // .dashboard-2021-whitebox-rowthree-2{
  //     width: 100%;
  //     margin-left: 0px;
  // }
  // .dashboard-2021-whitebox-rowthree-3{
  //     width: 100%;
  //     margin-right: 0px;
  // }
  .row-2-whitebox-chart-standin-wrap {
    display: block;
  }
}

@media (max-width: 998px) {
  .row-3-whitebox-standin-non {
    display: block;
  }

  .row-3-whitebox-non {
    display: none;
  }

  .dashboard-2021-whitebox-rowone-col3-non {
    margin-left: 0px;
    margin-top: 16px;
  }

  .dashboard-2021-whitebox-rowone-non {
    margin-right: 0px;
  }

  .row-3-whitebox-chart {
    display: none;
  }

  .row-3-whitebox-chart-standin {
    display: block;
  }

  .dashboard-2021-whitebox-rowthree-1 {
    width: calc(100% / 1);
  }

  .dashboard-2021-whitebox-rowthree-3 {
    width: calc(100% / 1);
    margin-left: 0px;
    margin-right: 10px;
  }
}

@media (max-width: 938px) {
  .row-2-whitebox-standin {
    display: block;
  }

  .row-2-whitebox {
    display: none;
  }

  .dashboard-2021-whitebox-rowone {
    margin-right: 0px;
  }

  .dashboard-2021-whitebox-rowone-col2 {
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 16px;
  }
}

@media (max-width: 836px) {
  .dashboard-2021-whitebox-header-row2-body-standin {
    flex-direction: column;
  }

  .row-2-colorbox {
    display: none;
  }

  .row-2-colorbox-standin {
    display: block;
  }

  .dashboard-2021-colorbox-row2-1 {
    margin-right: 0px;
  }

  .dashboard-2021-colorbox-row2-2 {
    margin-left: 0px;
    margin-bottom: 10px;
  }

  .dashboard-2021-colorbox-row2-3 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .dashboard-2021-colorbox-row2-4 {
    margin-left: 0px;
    margin-top: 10px;
  }

  .dashboard-2021-whitebox-pie-chart-info {
    padding-left: 30px;
  }
}

.dom-picture{
  width: 100%;
}