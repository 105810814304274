.btn-input-appbar{
    height: 100%;
    background-color: #4fa2df;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s;
}

.btn-input-appbar:hover{
    background-color:#3c98dc;
}