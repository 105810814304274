/* arrows */
select.data-pribadi-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../../../../../images/dropdown.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) calc(1em + 2px);
}

.data-pribadi-input2{
    width: 49.5%;
    height: 44px;
    background: #FAFAFA;
    border: 0.5px solid #D1D1D1;
    box-sizing: border-box;
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding: 0px 19px;
    color: #666666;
}

.data-pribadi-input2:focus{
    outline: none;
}

.data-pribadi-input2::placeholder{
    color: #C9C9C9;
}

.password-wraper-mod{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.eye-custom2{
    color: #D1D1D1;
    font-size: 26px;
    margin-left: -44px;
    margin-right: 10px;
    margin-top: 0px;
    cursor: pointer;
}

.eye-custom{
    color: #D1D1D1;
    font-size: 26px;
    margin-left: -40px;
    margin-right: 10px;
    margin-top: 0px;
    cursor: pointer;
}